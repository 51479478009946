const state = () => ({
  error: JSON.parse(localStorage.getItem('user')) || null,
  success: null,
  isLoaderActive: false,
  isLoaderBtnActive: false,
  isLoaderTableActive: false,
  isLoaderSkeletonActive: false,
  textLoading: null
});
const getters = {};
const actions = {
  setError({ commit }, payload) {
    commit('SET_ERROR', payload);
    payload
        ? localStorage.setItem('error', JSON.stringify(payload))
        : localStorage.removeItem('error');
  },
  setTextLoading({ commit }, payload) {
    commit('SET_TEXTLOADING', payload);
  },
  setSuccess({ commit }, payload) {
    commit('SET_SUCCESS', payload);
  },
  clearNotifications({ commit }) {
    localStorage.removeItem('error');
    commit('SET_ERROR', null);
    commit('SET_SUCCESS', null);
  },
  handleLoader({ commit }, bool) {
    commit('HANDLE_LOADER', bool);
  },
  handleLoaderBtn({ commit }, bool) {
    commit('HANDLE_LOADER_BTN', bool);
  },
  handleLoaderTable({ commit }, bool) {
    commit('HANDLE_LOADER_TABLE', bool);
  },
  handleLoaderSkeleton({ commit }, bool) {
    commit('HANDLE_LOADER_SKELETON', bool);
  },
};
const mutations = {
  SET_ERROR(state, payload) {
    localStorage.setItem('error', JSON.stringify(payload));
    state.error = payload;
  },
  SET_TEXTLOADING(state, payload) {
    state.textLoading = payload;
  },
  SET_SUCCESS(state, payload) {
    state.success = payload;
  },
  HANDLE_LOADER: function (state, bool) {
    state.isLoaderActive = bool;
  },
  HANDLE_LOADER_BTN: function (state, bool) {
    state.isLoaderBtnActive = bool;
  },
  HANDLE_LOADER_TABLE: function (state, bool) {
    state.isLoaderTableActive = bool;
  },
  HANDLE_LOADER_SKELETON: function (state, bool) {
    state.isLoaderSkeletonActive = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import {axiosData} from "@/store/api/axios";

const state = () => ({
  fiestas: JSON.parse(localStorage.getItem('fiestas')) || null,
  fiesta: JSON.parse(localStorage.getItem('fiesta')) || null,

});

const getters = {
  getFiestas(state) {
    return state.fiestas;
  },
  getFiesta(state) {
    return state.fiesta;
  },
};

const actions = {
  setFiesta({ commit }, payload) {
    commit('SET_FIESTA', payload);
    payload
        ? localStorage.setItem('fiesta', JSON.stringify(payload))
        : localStorage.removeItem('fiesta');
  },
  setFiestas({ commit }, payload) {
    commit('SET_FIESTAS', payload);
    payload
        ? localStorage.setItem('fiestas', JSON.stringify(payload))
        : localStorage.removeItem('fiestas');
  },

  async getActoByNegocioTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Fiestas');

    try {
      const data = await axiosData(
          true,
          'actos/fiestas/negociotag/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },



};

const mutations = {
  SET_FIESTAS(state, payload) {
    state.fiestas = payload;
    localStorage.setItem('fiestas', JSON.stringify(payload));
  },
  SET_FIESTA(state, payload) {
    state.fiesta = payload;
    localStorage.setItem('fiesta', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

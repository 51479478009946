import {axiosData} from "@/store/api/axios";

const state = () => ({
  negocios: JSON.parse(localStorage.getItem('negocios')) || null,
  negocio: JSON.parse(localStorage.getItem('negocio')) || null,
  isnegocios: JSON.parse(localStorage.getItem('isnegocios')) || null,
  negociotipo: JSON.parse(localStorage.getItem('negociotipo')) || null,
  negociopromotor: JSON.parse(localStorage.getItem('negociopromotor')) || null,
  permisos: JSON.parse(localStorage.getItem('permisos')) || null,
  permiso: JSON.parse(localStorage.getItem('permiso')) || null,

});

const getters = {
  getNegocios(state) {
    return state.negocios;
  },
  getPermisos(state) {
    return state.permisos;
  },
  getNegocio(state) {
    return state.negocio;
  },
  getPermiso(state) {
    return state.permiso;
  },
  getNegocioPromotor(state) {
    return state.negociopromotor;
  },
  getIsNegocios(state) {
    return state.isnegocios;
  },
  getNegocioTipo(state) {
    return state.negociotipo;
  },
};

const actions = {
  setNegocios({ commit }, payload) {
    commit('SET_NEGOCIOS', payload);
    payload
        ? localStorage.setItem('negocios', JSON.stringify(payload))
        : localStorage.removeItem('negocios');
  },
  async setNegocio({ commit }, payload) {
    try {

      // Commit al estado
      commit('SET_NEGOCIO', payload);

      // Actualizar localStorage
      localStorage.setItem('negocio', JSON.stringify(payload));
    } catch (error) {
      console.error('Error en setNegocio:', error);

      // Limpia el negocio en localStorage si ocurre un error
      localStorage.removeItem('negocio');
    }
  },

  setPermisos({ commit }, payload) {
    commit('SET_PERMISOS', payload);
    payload
        ? localStorage.setItem('permisos', JSON.stringify(payload))
        : localStorage.removeItem('permisos');
  },
  setPermiso({ commit }, payload) {
    commit('SET_PERMISO', payload);
    payload
        ? localStorage.setItem('permiso', JSON.stringify(payload))
        : localStorage.removeItem('permiso');
  },
  setNegocioPromotor({ commit }, payload) {
    commit('SET_NEGOCIOPROMOTOR', payload);
    payload
        ? localStorage.setItem('negociopromotor', JSON.stringify(payload))
        : localStorage.removeItem('negociopromotor');
  },
  setIsNegocio({ commit }, payload) {
    commit('SET_ISNEGOCIO', payload);
    payload
        ? localStorage.setItem('isnegocios', JSON.stringify(payload))
        : localStorage.removeItem('isnegocios');
  },
  setNegocioTipo({ commit }, payload) {
    commit('SET_NEGOCIOTIPO', payload);
    payload
        ? localStorage.setItem('negociotipo', JSON.stringify(payload))
        : localStorage.removeItem('negociotipo');
  },


  async getTipoListabyNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Tipo de Listas');
    try {
      const data = await axiosData(
          true,
          'tipolistas/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},

};

const mutations = {
  SET_NEGOCIOS(state, payload) {
    state.negocios = payload;
    localStorage.setItem('negocios', JSON.stringify(payload));

  },
  SET_NEGOCIO(state, payload) {
    state.negocio = payload;
    localStorage.setItem('negocio', JSON.stringify(payload));
  },
  SET_PERMISOS(state, payload) {
    state.permisos = payload;
    localStorage.setItem('permisos', JSON.stringify(payload));

  },
  SET_PERMISO(state, payload) {
    state.permiso = payload;
    localStorage.setItem('permiso', JSON.stringify(payload));
  },
  SET_NEGOCIOPROMOTOR(state, payload) {
    state.negociopromotor = payload;
    localStorage.setItem('negociopromotor', JSON.stringify(payload));
  },
  SET_ISNEGOCIO(state, payload) {
    state.isnegocios = payload;
    localStorage.setItem('isnegocios', JSON.stringify(payload));
  },
  SET_NEGOCIOTIPO(state, payload) {
    state.negociotipo = payload;
    localStorage.setItem('negociotipo', JSON.stringify(payload));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import {axiosData} from "@/store/api/axios";

const state = () => ({
  listas: JSON.parse(localStorage.getItem('listas')) || null,
  lista: JSON.parse(localStorage.getItem('lista')) || null,

});

const getters = {
  getListas(state) {
    return state.listas;
  },
  getLista(state) {
    return state.lista;
  },
};

const actions = {
  setLista({ commit }, payload) {
    commit('SET_LISTA', payload);
    payload
        ? localStorage.setItem('lista', JSON.stringify(payload))
        : localStorage.removeItem('lista');
  },
  setListas({ commit }, payload) {
    commit('SET_LISTAS', payload);
    payload
        ? localStorage.setItem('listas', JSON.stringify(payload))
        : localStorage.removeItem('listas');
  },

  async ingresaReservaNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reserva/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},

  async getListaFiestaReportePaloteo(_, payload) {
    // Validar el payload
    if (!payload || !payload.id) {
      console.error("Payload inválido:", payload);
      return [];
    }

    this.dispatch('ui/handleLoaderBtn', true); // Activar loader

    try {
      // Construir los parámetros de consulta (query params)
      let queryParams = new URLSearchParams();
      const { puertaFiltroRequest } = payload;

      if (puertaFiltroRequest) {
        if (puertaFiltroRequest.fromTime && puertaFiltroRequest.toTime) {
          queryParams.append('fromTime', puertaFiltroRequest.fromTime);
          queryParams.append('toTime', puertaFiltroRequest.toTime);
        }
        if (puertaFiltroRequest.promotorId) {
          queryParams.append('promotorId', puertaFiltroRequest.promotorId);
        }
      }

      const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

      // Realizar la solicitud
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/paloteos/${payload.id}${queryString}`,
          'get',
          false,
          false
      );

      // Validar el resultado de la solicitud
      if ([404, 400, null, undefined].includes(data)) {
        return [];
      }

      return data; // Retornar datos si son válidos
    } catch (error) {
      console.error("Error en getListaFiestaReportePaloteo:", error);
      return []; // Retornar un array vacío en caso de error
    } finally {
      this.dispatch('ui/handleLoaderBtn', false); // Desactivar loader
    }
  },

  async borrarLista(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/'+id,
          null,
          'delete',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async ingresaEnvioListaPromotorNegocioQR(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/qr/email',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async ingresaListaPromotorNegocioQR(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/qr',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async saveListaFiestaNueva(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async damePorFiestaPromotorHash(_, payload) {
    // Verificar el payload
    if (!payload || !payload.fId || !payload.nTg) {
      console.error("Payload inválido:", payload);
      return [];
    }

    this.dispatch('ui/handleLoaderBtn', true); // Activar loader
    try {
      // Realizar la solicitud
      const data = await axiosData(
          true,
          `lista/fiesta/promotor/hash/${payload.fId}/${payload.nTg}`,
          'get',
          false,
          false
      );

      // Validar el resultado de la solicitud
      if ([404, 400, null].includes(data)) {
        return [];
      }

      return data; // Retornar datos si son válidos
    } catch (error) {
      console.error("Error en damePorFiestaPromotorHash:", error);
      await this.dispatch('session/muestraErrores', error); // Manejo de errores
      return []; // Retornar un array vacío en caso de error
    } finally {
      this.dispatch('ui/handleLoaderBtn', false); // Desactivar loader
    }
  },

  async damePorFiestaPromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/promotor/'+payload.fiestaId+'/'+payload.promotorId,
          'get',
          false,
          false
      );
      this.dispatch('ui/handleLoaderBtn', false);

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }}
};

const mutations = {
  SET_LISTAS(state, payload) {
    state.listas = payload;
    localStorage.setItem('listas', JSON.stringify(payload));
  },
  SET_LISTA(state, payload) {
    state.lista = payload;
    localStorage.setItem('lista', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

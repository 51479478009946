import Vue from "vue";
import Vuex from "vuex";
import sessionModule from './store/modules/session';
import uiModule from './store/modules/ui';
import businessModule from './store/modules/business';
import fiestasModule from './store/modules/fiestas';
import listasModule from './store/modules/listas';
import promotoresModule from './store/modules/promotores';
import reservasModule from './store/modules/reservas';
Vue.use(Vuex);
const getDefaultState = () => {
    return {
        isMobile: false,
        routes: null,
        routesObject: null,
        showSpinner: false,
        showLoginbox:false,
        showInscribete: false,
    }
}
const state = getDefaultState()

export default new Vuex.Store({
    state,
  getters: {
  },
  mutations: {
      updateResolution(state, payload) {
          state.isMobile = payload;
      },
  },
  actions: {
      updateResolution: ({ commit }, payload) =>
          commit('updateResolution', payload),
  },
    plugins:[],
    modules:{
        session:sessionModule,
        business: businessModule,
        ui: uiModule,
        fiestas: fiestasModule,
        listas: listasModule,
        promotores: promotoresModule,
        reservas : reservasModule,
    }
});
